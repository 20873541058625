<template>
  <form id="VehicleSearchForm" @submit.prevent="submit">
    <div class="form-group">
      <label for="BodyStyleDropdown">Select a body style:</label>
      <body-style-picker id="BodyStylePicker" v-model="selectedOptions.bodyStyles" />
    </div>

    <div class="form-group">
      <label for="PricePicker">What's your price range?</label>
      <span class="price-range-text">
        {{ priceRangeLowText }} - {{ priceRangeHighText }}
      </span>

      <price-picker id="PricePicker" v-model="selectedOptions.selectedPriceCategory" />
    </div>
    <div class="form-group">
      <label for="FeatureSelector">What are you looking for?</label>
      <feature-selector id="FeatureSelector" v-model="selectedFeatures" />
    </div>
    <div>
        <div class="error" v-show="formInvalid && !selectedFeatures.length">
          <p>Please select at least one feature</p>
        </div>
    </div>

    <div id="VehicleSearchFormFooter">
      <button class="button btn tertiary" type="submit">
        <div>Search &amp; compare <span class="accent">&raquo;</span></div>
      </button>
    </div>
  </form>
</template>

<script>
import { Constants } from "Services/Constants";
const PricePicker = () => import("Components/VehicleSearch/PricePicker");
const FeatureSelector = () =>
  import("Components/VehicleSearch/FeatureSelector");
const BodyStylePicker = () =>
  import("Components/VehicleSearch/BodyStylePicker");
export default {
  data() {
    let features = Constants.features;
    features.forEach((f) => {
      const indexOfFeature = this.$store.state.searchOptions.features.findIndex(
        (x) => x.name == f.value
      );
      f.selected = indexOfFeature > -1;
      f.rank = f.selected
        ? this.$store.state.searchOptions.features[indexOfFeature].rank
        : 0;
    });

    return {
      selectedOptions: this.$store.state.searchOptions,
      features: features,
      formInvalid: false,
      selectedFeatures: [],
    };
  },
  computed: {
    bodyStyles() {
      return this.$store.state.allBodyStyles;
    },
    priceRangeLowText() {
      const priceRangeThousands = (this.selectedOptions.selectedPriceCategory?.min || this.selectedOptions.priceLow) / 1000;
      return `$${priceRangeThousands}k`;
    },
    priceRangeHighText() {
      const priceRangeThousands = (this.selectedOptions.selectedPriceCategory?.max || this.selectedOptions.priceHigh) / 1000;
      return `$${priceRangeThousands}k`;
    }
  },
  methods: {
    formIsValid() {
      if (this.selectedFeatures.length < 1) return false;
      return true;
    },
    submit() {
      this.formInvalid = false;

      if (this.formIsValid()) {
        this.selectedOptions.priceLow =
          this.selectedOptions.selectedPriceCategory.min;
        this.selectedOptions.priceHigh =
          this.selectedOptions.selectedPriceCategory.max;

        this.selectedOptions.features = this.selectedFeatures.map((x) => ({
          name: x.value,
          rank: x.rank,
        }));
        this.$store.commit("updateOptions", this.selectedOptions);

        this.$router.push({
          name: "compare",
        });
      } else {
        this.formInvalid = true;
      }
    },
  },
  components: {
    PricePicker,
    FeatureSelector,
    BodyStylePicker
  },
};
</script>

<style lang="scss">
#VehicleSearchFormFooter {
  margin-top: 33px;
}

.form-group {
  margin-bottom: 13px;
  @include media-breakpoint-down(md) {
   padding-right: 10px;
  }
}
#VehicleSearchForm {
  h3 {
    padding-bottom: 10px;
    color: white;
  }

  label {
    color: #434343;
    font-size: 0.875rem;
    font-weight: 800;
    margin-bottom: 0;
  }

  .error {
    position: relative;
    top: -10px;
    p {
      padding-left: 5px;
      padding-top: 0;
      margin-bottom: 0;      
    }
  }

  .button.btn {
    width: 200px;
    font-size: 1.062rem;
    padding: 4px 15px 4px 15px;
  }

  .price-range-text {
    font-size: 0.75rem;
    color: #333333;
    margin-left: 10px;
  }

  #PricePicker {
    margin-left: 2px;
    margin-right: 2px;
  }
}
</style>
